export interface ProjectTypeTaskBundles {
  taskBundleIds: string[]
  projectTypeId: string
  projectStatuses: {
    taskBundleIds: string[]
    projectStatusId: string
  }[]
}

export interface IDeleteProjectRoleBody {
  newProjectRoleId?: string
}

export interface IDeleteProjectTypeBody {
  newProjectTypeId?: string
}

export interface IProjectDeleteBody {
  deleteTimeTrackings: boolean
}

export interface IRemoveProjectRoleBody {
  oldProjectRoleId?: string
  newProjectRoleId?: string
}

export interface IDeleteProjectStatusBody {
  projectStatusId: string
}

export interface ProjectTemplateImportOptions {
  name: string
  importProjectDetails?: boolean
  importProjectSettings?: boolean
  importProjectStatuses?: boolean
  importAutomations?: boolean
  importTaskStatuses?: boolean
  importTaskAndLists?: boolean
  referenceDate?: Date
}

export const CONNECT_ACCESS_RESTRICTION_SAME_DOMAIN = 'connect-access-restriction-same-domain'
