/**
 * Converts a string or number to an enum value.
 * @param {T} enumType
 * @param {string | number} value
 * @returns {T[keyof T] | undefined}
 */
export function toEnum<T extends { [k: string]: string | number }>(
  enumType: T,
  value: string | number
): T[keyof T] | undefined {
  return isInEnum(enumType, value) ? (value as T[keyof T]) : undefined
}

/**
 * Checks if a value is a member of an enum.
 * @param {T} enumType
 * @param {string | number} value
 * @returns {boolean}
 */
export function isInEnum<T extends { [k: string]: string | number }>(enumType: T, value: string | number): boolean {
  return Object.values(enumType).includes(value)
}
