import { Component, Input, ViewChild, ElementRef, HostBinding, OnInit } from '@angular/core'
import { NgIf } from '@angular/common'

@Component({
  selector: 'aw-file-icon',
  templateUrl: './file-icon.component.html',
  styleUrls: ['./file-icon.component.scss'],
  imports: [NgIf],
  standalone: true
})
export class FileIconComponent implements OnInit {
  @Input() extension: string
  @Input() name: string
  @Input() mimeType = ''
  @Input() @HostBinding('class') size: 'xxxsmall' | 'xxsmall' | 'xsmall' | 'small' | 'normal' | 'big' = 'normal'

  @ViewChild('fileExtension') fileExtension: ElementRef

  colorClass: string

  extensionIcon: string

  constructor(public element: ElementRef) {}

  ngOnInit(): void {
    // If the extension is not set, extract the extension from the filename
    if (!this.extension && this.name) {
      const regExp = /(?:\.([^.]+))?$/
      this.extension = regExp.exec(this.name)[1]
    }

    const extension = this.extension?.toLowerCase()

    if (['doc', 'docx', 'document'].includes(extension)) {
      this.colorClass = 'blue'
      this.extensionIcon = 'W'
    } else if (['pdf'].includes(extension)) {
      this.colorClass = 'red'
      this.extensionIcon = 'PDF'
    } else if (
      ['bmp', 'gif', 'ico', 'jpeg', 'jpg', 'png', 'svg', 'tif', 'tiff', 'heic', 'eps', 'raw', 'webp'].includes(
        extension
      )
    ) {
      this.colorClass = 'royalBlue'
    } else if (['sketch'].includes(extension)) {
      this.colorClass = 'yellow'
    } else if (['pps', 'ppt', 'pptx', 'presentation'].includes(extension)) {
      this.colorClass = 'orange'
      this.extensionIcon = 'P'
    } else if (['aif', 'cda', 'mid', 'midi', 'mp3', 'mpa', 'ogg', 'wav', 'wma', 'wp'].includes(extension)) {
      this.colorClass = 'lightGreen'
    } else if (['xls', 'xls', 'xlsx', 'spreadsheet'].includes(extension)) {
      this.colorClass = 'green'
      this.extensionIcon = 'X'
    } else if (['csv'].includes(extension)) {
      this.colorClass = 'green'
    } else if (
      ['avi', 'flv', 'h264', 'm4v', 'mkv', 'mov', 'mp4', 'mpg', 'mpeg', 'rm', 'swf', 'vob', 'wmv', 'webm'].includes(
        extension
      )
    ) {
      this.colorClass = 'pink'
    } else if (['zip'].includes(extension)) {
      this.colorClass = 'deepYellow'
      this.extensionIcon = 'ZIP'
    } else if (['rar'].includes(extension)) {
      this.colorClass = 'purple'
      this.extensionIcon = 'RAR'
    } else if (['ai'].includes(extension)) {
      this.colorClass = 'deepOrange'
      this.extensionIcon = 'Ai'
    } else if (['indd'].includes(extension)) {
      this.colorClass = 'deepPink'
      this.extensionIcon = 'In'
    } else if (['aep'].includes(extension)) {
      this.colorClass = 'deepBlue'
      this.extensionIcon = 'Ae'
    } else if (['prproj'].includes(extension)) {
      this.colorClass = 'deepBlue'
      this.extensionIcon = 'Pr'
    } else if (['psd', 'ps'].includes(extension)) {
      this.colorClass = 'lightBlue'
      this.extensionIcon = 'Ps'
    } else if (['xd'].includes(extension)) {
      this.colorClass = 'lightPink'
      this.extensionIcon = 'Xd'
    }
  }
}
