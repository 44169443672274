<figure *ngIf="project" class="avt" [ngClass]="'avt--' + size">
  <div class="aw-2-pos--relative">
    <figure
      class="avt__image"
      [class.avt__image--fallback]="!imageUrl || imageHasError"
      [style.height.px]="avatarPixelSizes[size]"
      [style.minWidth.px]="avatarPixelSizes[size]"
    >
      <img
        *ngIf="imageUrl && !imageHasError; else imageFallback"
        priority
        [ngSrc]="imageUrl"
        [attr.alt]="project.name"
        [width]="avatarPixelSizes[size]"
        [height]="avatarPixelSizes[size]"
        (error)="onImageError()"
      />

      <ng-template #imageFallback>
        <i class="aw-2-icn" [ngClass]="'aw-2-icn--' + size">folder_open</i>
      </ng-template>

      <span *ngIf="Project.isProject(project) && project.isPrivate" class="avt__image__private">
        <i class="aw-2-icn">lock</i>
      </span>
    </figure>

    <img
      *ngIf="Project.isProject(project) && project.isExternal && !project.isPrivate && size !== Size.xs"
      priority
      class="avt__workspace-image"
      [ngClass]="'avt__workspace-image--' + size"
      [ngSrc]="project.getWorkspaceImage()"
      [width]="subAvatarPixelSizes[size]"
      [height]="subAvatarPixelSizes[size]"
    />
  </div>

  <figcaption
    *ngIf="showText"
    class="avt__text"
    [class.avt__text--multiline]="multiline"
    [class.avt__text--light]="forceLightTheme"
  >
    <span *ngIf="Project.isProject(project) && showSecondaryText && project.company" class="avt__text__secondary aw-2-txt--left">
      {{ project.company.name }}
    </span>

    <span
      class="avt__text__primary aw-2-txt--left"
      [class.avt__text__link]="link"
      [ngStyle]="{ '-webkit-line-clamp': lines }"
      [attr.title]="project.name"
      (click)="linkClick($event)"
    >
      {{ project.name }}
    </span>
  </figcaption>
</figure>
