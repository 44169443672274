<article class="wrapper" [ngClass]="[size, view, showSmallDescription ? 'show-small-description' : '']">
  <!-- File Preview -->
  <div
    class="preview-wrapper aw-2-pos--relative aw-2-b--radius-m"
    [class.aw-2-sh--xs]="size === Size.s"
    [class.aw-2-sh--s]="!showSmallDescription && size !== Size.s"
  >
    <!-- Loader -->
    @if (isLoading) {
      <aw-dots-loader class="aw-2-pos--center-all" [color]="Color.Fog" />
    }

    <!-- Image thumbnail -->
    @if (showImageThumbnail && view === 'image' && file?.isPreviewSupported()) {
      <img [hidden]="isLoading" [src]="previewUrl" (load)="imageLoaded()" (error)="imageLoadingError()" />
    }

    <!-- Icon wrapper -->
    @if (['icon', 'externalFile'].includes(view)) {
      <div class="icon-wrapper">
        <aw-file-icon
          [extension]="file.fileExtension"
          [externalProvider]="file.externalProvider"
          [size]="size === Size.s ? Size.l : Size.xl"
        />
      </div>
    }

    <!-- Hidden For Connect Icon -->
    @if (isConnectHideableEnabled() && file.isHiddenForConnectUsers) {
      <figure class="hidden-icon" [class.hidden-icon--s]="size === Size.s">
        <i
          class="aw-2-icn aw-2-icn--red"
          [class.aw-2-icn--s]="size === Size.s"
          [awTooltip]="translations.hiddenFromExternals"
        >
          visibility_off
        </i>
      </figure>
    }

    <!-- Actions -->
    <div class="actions-wrapper aw-2-flx aw-2-pos--absolute">
      <!-- Show External File -->
      @if (view === 'externalFile') {
        <a target="_blank" [href]="file.externalFileUrl">
          <aw-fab-button
            [icon]="'open_in_new'"
            [size]="size === Size.s ? Size.s : Size.m"
            [awTooltip]="q.translations.common.open"
            [actionTooltip]="true"
            [suppressClick]="true"
            [showDelay]="200"
          />
        </a>
      }

      <!-- Download -->
      @if (size !== Size.s && view !== 'externalFile') {
        <a #downloadLink class="clean-link" [href]="file.downloadUrl">
          <aw-fab-button
            [icon]="'file_download'"
            [size]="Size.m"
            [awTooltip]="q.translations.common.download"
            [actionTooltip]="true"
            [suppressClick]="true"
            [showDelay]="200"
          />
        </a>
      }

      <!-- Open Carousel -->
      @if (!showTinyDescription && supportsPreview) {
        <aw-fab-button
          [icon]="'search'"
          [size]="size === Size.s ? Size.s : Size.m"
          [awTooltip]="q.translations.common.preview"
          [actionTooltip]="true"
          [showDelay]="200"
          (click)="onView()"
        />
      }

      <!-- Delete -->
      @if (size !== Size.s && canEdit) {
        <aw-fab-button
          class="aw-2-btn--clear"
          [icon]="'delete'"
          [size]="Size.m"
          [awTooltip]="q.translations.common.delete"
          [actionTooltip]="true"
          [showDelay]="200"
          [color]="Color.Red"
          (click)="onDelete()"
        />
      }
    </div>
  </div>

  @let isMidSize = size === Size.m || size === Size.l;

  <!-- File Info -->
  @if (showDescription) {
    <div class="info-wrapper aw-2-flx" [ngClass]="isMidSize ? 'aw-2-pt--m' : ''">
      @if (uploader?.id && isMidSize) {
        <aw-user-avatar [user]="uploader" [size]="Size.m" [showText]="false" [awTooltip]="uploader?.name" />
      }

      <!-- Info Text -->
      @if (isMidSize || (size === Size.s && showSmallDescription)) {
        <div class="info-wrapper__text aw-2-flx--flex-1">
          <!-- File Name -->
          <p class="info-wrapper__text__filename aw-2-txt--truncate aw-2-txt--night aw-2-txt--bold" [title]="file.name">
            {{ file.name }}
          </p>

          @if (size === Size.l || (size === Size.m && !file.task?.taskStatus)) {
            <aw-date-time-label
              class="date aw-2-txt--body-tiny aw-2-txt--bold"
              [class.aw-2-ml--auto]="Size.m"
              [date]="file.updatedOn"
            />
          }

          @if (file.task?.taskStatus && size !== Size.s) {
            <aw-task-avatar
              class="info-wrapper__text__task-avatar"
              [task]="file.task"
              [size]="Size.xs"
              [link]="true"
              [hideProject]="true"
            />
          }

          <!-- File Size -->
          @if (size === Size.s && showSmallDescription) {
            <p class="info-wrapper__text__file-size aw-2-txt--steel aw-2-txt--bold">
              {{ file.size | fileSize }}
            </p>
          }
        </div>
      }

      @if (showTinyDescription && !showSmallDescription && size === Size.s) {
        <p class="info-wrapper__tiny-description aw-2-d--block aw-2-txt--truncate aw-2-txt--bold aw-2-pt--xs">
          {{ file.name }}
        </p>
      }
    </div>
  }
</article>
